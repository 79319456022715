import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { Breakpoint, Container } from '@mui/material';
import { Crumb, EntityType } from 'common/types';
import HoldingsDownloadUpload from 'components/clients/HoldingsDownloadUpload';
import PerformanceSummaryDocUpload from 'components/investments/SummaryDocUpload';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useReactQuery } from 'store/hooks';
import { selectFundsToCompare } from 'store/recommendation/selectors';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';
import MuiBreadcrumbs from '../breadcrumbs';
import MuiButton from '../button';

const StyledWrapper = styled.section`
  background-color: white;
  padding: 1.5rem 0;
  margin: 0 auto;

  h1,
  h2 {
    font-weight: 300;
    line-height: 1em;
    margin-bottom: 0;
  }

  h1 {
    font-size: 2.625rem;
  }

  h2 {
    font-size: 30px;
  }

  .statusText {
    color: #525863;
    font-size: 12px;
    font-weight: 500;
    background-color: #ebf5ff;
    padding: 5px 18px;
    border-radius: 15px;
  }

  img {
    width: 100%;
  }

  a.platformPerformanceSummary {
    font-size: 14px;
  }

  .close {
    cursor: pointer;
    color: #949aa6;
  }

  &.bottomBorder {
    border-bottom: 1px solid #ddd;
  }

  &.investmentDetails {
    h1 {
      font-size: 30px;
      font-weight: 300;
    }
  }
`;

interface Props {
  crumbs?: Array<Crumb>;
  children: React.ReactNode;
  className?: string;
  perfSummary?: boolean;
  closeIcon?: boolean;
  closeFunction?: () => void;
  containerMaxWidth?: Breakpoint;
  holdings?: boolean;
  compare?: any;
}

const SubHeader = ({
  crumbs,
  children,
  className,
  perfSummary = false,
  closeIcon = false,
  closeFunction,
  containerMaxWidth = 'xl',
  holdings = false,
  compare,
}: Props) => {
  const [performanceSummaryDocLink, setPerformanceSummaryDocLink] = useState<string>('');
  const [objectKey, setObjectKey] = useState<{ object_key: string } | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const id: string = location.pathname.split('/')[1];
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const fundsToCompare = useAppSelector(selectFundsToCompare);
  //const isRecommendationVisible = process.env.REACT_APP_SHOW_RECOMMENDATION_ENGINE_FLOW === 'true';

  const { mutate: fetchPreSignedUrl, data: documentLink } = useMutationQuery({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    params: objectKey,
  });

  useReactQuery(
    ['performanceSummary'],
    {
      url: 'qaip/v1/documentmanagement/performanceSummary?doc_category_id=PerformanceSummary',
    },
    {
      refetchOnMount: true,
      onSuccess: (data: any[]) => {
        (async function () {
          const documentData: { object_key: string } = { object_key: data[0].docLink };
          await setObjectKey(documentData);
          await fetchPreSignedUrl();
        })();
      },
    },
  );

  useEffect(() => {
    if (documentLink?.url) setPerformanceSummaryDocLink(documentLink?.url);
  }, [documentLink]);

  const compareClick = () => {
    navigate('/investments/compare');
  };

  return (
    <StyledWrapper className={className}>
      <Container maxWidth={containerMaxWidth} className='container-lr-padding'>
        <MuiBreadcrumbs crumbs={crumbs}>
          {id === 'investments' && perfSummary && isQualisUser && <PerformanceSummaryDocUpload />}
          {perfSummary && (
            <a
              className='text-decoration-none  '
              href={performanceSummaryDocLink}
              target='_blank'
              rel='noreferrer noopener'
            >
              <MuiButton>Platform Performance</MuiButton>
            </a>
          )}
          {holdings && <HoldingsDownloadUpload />}
          {compare && (
            <MuiButton buttonClick={compareClick} variant='outlined' className='ml-3'>
              Compare Funds ({fundsToCompare.length})
            </MuiButton>
          )}
          {closeIcon && <Close className='close' onClick={closeFunction} />}
        </MuiBreadcrumbs>
        {children}
      </Container>
    </StyledWrapper>
  );
};

export default SubHeader;
